<!-- 我的反馈 -->
<template>
  <div>
    <div class="feedBack">
      <p class="title">反馈</p>
      <div class="form">
        <el-table
          :data="tableData"
          style="width: 100%"
          highlight-current-row
          @current-change="tableCurrentChange"
          @row-click="rowClick"
        >
          <el-table-column prop="title" label="标题" align="center"  width="180"
            show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-html="scope.row.title"> </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="type"
            label="反馈类型"
            align="center"
            width="80"
          >
          </el-table-column>
          <el-table-column prop="re_id" label="相关单号" align="center">
			<template slot-scope="scope">
              <div>
                <P v-if="scope.row.re_id">
					<!-- <a target="_blank" :href="scope.row.url"> -->
					{{scope.row.re_id}}
					<!-- </a> -->
				</P>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="反馈信息"
            align="center"
            width="220"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="_create_time" label="时间" align="center">
          </el-table-column>
          <el-table-column prop="_reply_time" label="答复" align="left">
            <template slot-scope="scope">
              <div>
                <P v-if="!scope.row._reply_time">未回复</P>

                <P v-if="scope.row._reply_time">{{ scope.row._reply_time }}</P>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
          :page-size="pageSize"
          :current-page="page"
          hide-on-single-page
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { feedback } from "@/api/me.js";

export default {
  data() {
    //这里存放数据
    return {
      me: "",
      page: 1,
      pageSize: 10,
      total:0,
      tableData: [
       
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
    this.getFeedbackList();
  },
  //方法集合
  methods: {
    // 反馈列表
    getFeedbackList() {
      feedback({
        page: this.page,
        page_size: this.pageSize,
      })
        .then(({ data }) => {
          this.tableData = data.data;
          this.total=data.total
        })
        .catch((err) => {
        });
    },
    // 反馈分页
    currentChange(e) {
      this.page=e
      this.getFeedbackList()
    },
    // 点击某一项
    rowClick(e){
      
      this.$router.push({path:'/me/meFeedbackDetaild',query:{type:'反馈',title:e.type,id:e.id}})
    },

    tableCurrentChange(val) {
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
  },
};
</script>
<style  lang='less' scoped>
.feedBack {
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 2.3% 8.3% 3.4% 4.3%;
  .title {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #191919;
  }
  .form {
    margin: 3.6% 0 6.2%;

    border: 1px solid #979797;
    .info {
      overflow: hidden; //多出部分隐藏
      white-space: nowrap; //一行显示
      text-overflow: ellipsis; //是否显示省略号
    }
    ::v-deep {
      .el-table__row{
        cursor: pointer;
      }
      .el-table td,
      .el-table th {
        border-bottom: 1px solid #979797;
        padding: 0;
        height: 36px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #191919;
      }
    }
  }
  .page {
    display: flex;
    justify-content: center;
  }
}
.feedBackBtn {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1.5%;
  div {
    width: 86px;
    height: 30px;
    background: #00957e;
    box-shadow: 0px 2px 14px 0px rgba(164, 164, 164, 0.5);
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    letter-spacing: 1px;
    cursor: pointer;
  }
}
</style>